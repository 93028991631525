import { getMallPageAbsolutePath } from '@/utils/index'
import AdminLayout from '@/layouts/admin-layout.vue'
import BlankLayout from '@/layouts/blank-layout.vue'
import PortalLayout from '@/layouts/portal-layout.vue'
import AccountLayout from '@/layouts/account-layout.vue'
import IconMenuHome from '@/assets/icons/icon-menu-home.svg'
import IconMenuOrder from '@/assets/icons/icon-menu-order.svg'
import IconMenuProduct from '@/assets/icons/icon-menu-product.svg'
import IconMenuCustomer from '@/assets/icons/icon-menu-customer.svg'
import IconMenuReport from '@/assets/icons/icon-menu-report.svg'
import IconMenuDiscount from '@/assets/icons/icon-menu-discount.svg'
import IconMenuOnlineShop from '@/assets/icons/icon-menu-online-shop.svg'
import IconMenuSetting from '@/assets/icons/icon-menu-setting.svg'
import IconMenuApplication from '@/assets/icons/icon-application.svg'
import IconEyeFill from '@/assets/icons/icon-eye-fill.svg'
// 获取管理端外壳布局的路由
export const getAdminLayoutRoutes = () => [
  {
    path: '/',
    component: AdminLayout,
    redirect: '/order',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: $t('common.adminMenu.home'),
          icon: IconMenuHome,
          hideAppTitleBar: true,
          pv: 'spmerchant_home_show',
        },
      },
      {
        path: '/order',
        name: 'order',
        component: BlankLayout,
        meta: { title: $t('common.adminMenu.order'), icon: IconMenuOrder },
        redirect: '/order/order-list',
        children: [
          {
            path: '/order/order-list',
            name: 'order-list',
            component: () => import('@/views/order/order-list/index.vue'),
            meta: {
              title: $t('order.orderList.title'),
              hideAppTitleBar: true,
              pv: 'spmerchant_orderlist_show',
            },
          },
          {
            path: '/order/order-detail',
            name: 'order-detail',
            component: () => import('@/views/order/order-detail/index.vue'),
            hidden: true,
            meta: {
              title: $t('order.orderDetail.orderDetailText'),
              hideAppTitleBar: true,
              pv: 'spmerchant_orderdetail_show',
              parentPath: '/order/order-list',
            },
          },
          {
            path: '/order/order-edit',
            name: 'order-edit',
            component: () => import('@/views/order/order-edit/index.vue'),
            hidden: true,
            meta: {
              title: $t('order.orderEdit.addOrder'),
              hideAppTitleBar: true,
              pv: 'spmerchant_orderedit_show',
              parentPath: '/order/order-list',
            },
          },
          {
            path: '/order/throw-order-list',
            name: 'throw-order-list',
            component: () => import('@/views/order/throw-order-list/index.vue'),
            meta: {
              title: $t('order.throw.throw.title'),
              hideAppTitleBar: true,
              pv: 'spmerchant_orderlist_show',
            },
          },
          {
            path: '/order/throw-order-detail',
            name: 'throw-order-detail',
            component: () => import('@/views/order/throw-order-detail/index.vue'),
            hidden: true,
            meta: {
              title: $t('order.orderDetail.orderDetailText'),
              hideAppTitleBar: true,
              pv: 'spmerchant_orderdetail_show',
              parentPath: '/order/throw-order-list',
            },
          },
        ],
      },
      {
        path: '/product',
        name: 'product',
        component: BlankLayout,
        redirect: '/product/product-list',
        meta: {
          title: $t('common.adminMenu.product'),
          icon: IconMenuProduct,
          hideAppTitleBar: true,
        },
        children: [
          {
            path: '/product/product-list',
            name: 'product-list',
            component: () => import('@/views/product/product-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.productList'),
              hideAppTitleBar: true,
              pv: 'spmerchant_productlist_show',
            },
          },
          {
            path: '/product/product-edit',
            name: 'product-edit',
            hidden: true,
            component: () => import('@/views/product/product-edit/index.vue'),
            meta: {
              title: $t('order.orderEdit.editProduct'),
              hideAppTitleBar: true,
              pv: 'spmerchant_productedit_show',
              parentPath: '/product/product-list',
            },
          },
          {
            path: '/product/inventory-list',
            name: 'inventory-list',
            component: () => import('@/views/product/inventory-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.inventoryList'),
              hideAppTitleBar: true,
              pv: 'product_inventory_show',
            },
          },
          {
            path: '/product/product-group-list',
            name: 'product-group-list',
            component: () => import('@/views/product/product-group-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.productGroup'),
              hideAppTitleBar: true,
              pv: 'spmerchant_productgrouplist_show',
            },
          },
          {
            path: '/comment/comment-list',
            name: 'comment-list',
            component: () => import('@/views/comment/comment-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.reviews'),
              hideAppTitleBar: true,
              pv: 'spmerchant_commentlist_show',
            },
          },
          // 商品 - 评价 - 详情
          {
            path: '/comment/comment-details',
            name: 'comment-details',
            component: () => import('@/views/comment/comment-details/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.reviews'),
              hideAppTitleBar: true,
              parentPath: '/comment/comment-list',
            },
          },
        ],
      },
      {
        path: '/customer',
        name: 'customer',
        component: BlankLayout,
        redirect: '/customer/customer-group-list',
        meta: {
          title: $t('customer.selectTextCustomer'),
          icon: IconMenuCustomer,
          hideAppTitleBar: true,
        },
        children: [
          {
            path: '/customer/customer-list',
            name: 'customer-list',
            component: () => import('@/views/customer/customer-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.customerList'),
              hideAppTitleBar: true,
              pv: 'spmerchant_customerlist_show',
            },
          },
          {
            path: '/customer/customer-group-list',
            name: 'customer-group-list',
            component: () => import('@/views/customer/customer-group-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.customerGroup'),
              hideAppTitleBar: true,
              pv: 'spmerchant_customergrouplist_show',
            },
          },
          {
            path: '/customer/customer-detail',
            name: 'customer-detail',
            component: () => import('@/views/customer/customer-detail/index.vue'),
            hidden: true,
            meta: {
              title: $t('customer.customerDetail'),
              hideAppTitleBar: true,
              pv: 'spmerchant_customerdetail_show',
              parentPath: '/customer/customer-list',
            },
          },
          {
            path: '/customer/customer-edit',
            name: 'customer-edit',
            component: () => import('@/views/customer/customer-edit/index.vue'),
            hidden: true,
            meta: {
              title: $t('customer.editCustomer'),
              hideAppTitleBar: true,
              pv: 'spmerchant_customeredit_show',
              parentPath: '/customer/customer-list',
            },
          },
          {
            path: '/customer/message-list',
            name: 'message-list',
            component: () => import('@/views/customer/message-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.message'),
              hideAppTitleBar: true,
              pv: 'spmerchant_messagelist_show',
            },
          },
          {
            path: '/customer/message-detail',
            name: 'message-detail',
            component: () => import('@/views/customer/message-detail/index.vue'),
            hidden: true,
            meta: {
              title: $t('customer.messageDetail'),
              hideAppTitleBar: true,
              pv: 'spmerchant_messagedetail_show',
              parentPath: '/customer/message-list',
            },
          },
          {
            path: '/customer/message-send',
            name: 'message-send',
            component: () => import('@/views/customer/message-send/index.vue'),
            hidden: true,
            meta: {
              title: $t('customer.createMessage.sendMessage'),
              hideAppTitleBar: true,
              pv: 'spmerchant_messagesend_show',
              parentPath: '/customer/message-list',
            },
          },
        ],
      },
      {
        path: '/report',
        name: 'report',
        component: BlankLayout,
        redirect: '/report/customer-overview',
        meta: { title: $t('common.adminMenu.analytics'), icon: IconMenuReport },
        children: [
          {
            path: '/report/sales-overview',
            name: 'sales-overview',
            component: () => import('@/views/report/sales-overview/index.vue'),
            meta: {
              title: $t('common.adminMenu.dataReview'),
              hideAppTitleBar: true,
              pv: 'spmerchant_salesoverview_show',
            },
          },
          {
            path: '/report/customer-overview',
            name: 'customer-overview',
            component: () => import('@/views/report/customer-overview/index.vue'),
            meta: {
              title: $t('common.adminMenu.customerAnalytics'),
              hideAppTitleBar: true,
              pv: 'spmerchant_customeroverview_show',
            },
          },
        ],
      },
      {
        path: '/discount',
        name: 'discount',
        component: BlankLayout,
        meta: { title: $t('common.adminMenu.discount'), icon: IconMenuDiscount },
        redirect: '/discount/discount-list',
        hideChildrenInMenu: true,
        children: [
          {
            path: '/discount/discount-list',
            name: 'discount-list',
            component: () => import('@/views/discount/discount-list/index.vue'),
            meta: {
              title: $t('discount.discountList.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_discountlist_show',
            },
          },
          {
            path: '/discount/discount-edit/:id',
            name: 'discount-edit',
            component: () => import('@/views/discount/discount-edit/index.vue'),
            hidden: true,
            meta: {
              title: $t('discount.discountList.edit'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_discountedit_show',
            },
          },
        ],
      },
      {
        path: '/application',
        name: 'application',
        component: BlankLayout,
        meta: { title: $t('application.title'), icon: IconMenuApplication },
        redirect: '/application/application-list',
        hideChildrenInMenu: true,
        children: [
          {
            path: '/application/application-list',
            name: 'application-list',
            component: () => import('@/views/application/application-list/index.vue'),
            meta: {
              title: $t('application.applicationList.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_applicationlist_show',
            },
          },
          {
            path: '/application/google-analytics',
            name: 'google-analytics',
            component: () => import('@/views/application/google-analytics/index.vue'),
            meta: {
              title: $t('application.googleAnalytics.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_googleanalytics_show',
            },
          },
          {
            path: '/application/facebook-pixel',
            name: 'facebook-pixel',
            component: () => import('@/views/application/facebook-pixel/index.vue'),
            meta: {
              title: $t('application.facebookPixel.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_facebookpixel_show',
            },
          },
          {
            path: '/application/shop-move',
            name: 'shop-move',
            component: () => import('@/views/application/shop-move/index.vue'),
            meta: {
              title: $t('application.shopMove.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_shopmove_show',
            },
          },
        ],
      },
      // 在线商店
      {
        path: '/online-shop',
        name: 'online-shop',
        component: BlankLayout,
        meta: {
          title: $t('common.adminMenu.onlineShop'),
          icon: IconMenuOnlineShop,
          hideAppTitleBar: true,
          markIcon: IconEyeFill,
          markIconEvent: () => {
            window.open(getMallPageAbsolutePath())
          },
        },
        children: [
          {
            path: '/online-shop/shop-theme',
            name: 'shop-theme',
            component: () => import('@/views/online-shop/shop-theme/index.vue'),
            meta: {
              title: $t('common.adminMenu.shopTheme'),
              hideAppTitleBar: true,
              pv: 'spmerchant_shoptheme_show',
            },
          },
          // 页面
          {
            path: '/online-shop/page',
            name: 'online-shop-page',
            component: () => import('@/views/online-shop/page/page-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.onlineShopPage'),
              hideAppTitleBar: true,
            },
          },
          {
            path: '/online-shop/page/page-edit',
            name: 'online-shop-page-page-edit',
            component: () => import('@/views/online-shop/page/page-edit/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.onlineShopPage'),
              hideAppTitleBar: true,
              parentPath: '/online-shop/page',
            },
          },
          // 导航
          {
            path: '/online-shop/navigation',
            name: 'online-shop-navigation',
            component: () => import('@/views/online-shop/navigation/navigation-list/index.vue'),
            meta: {
              title: $t('common.adminMenu.onlineShopNav'),
              hideAppTitleBar: true,
            },
          },
          {
            path: '/online-shop/navigation/navigation-edit',
            name: 'online-shop/navigation/navigation-edit',
            component: () => import('@/views/online-shop/navigation/navigation-edit/index.vue'),
            hidden: true,
            meta: {
              title: $t('customer.editCustomer'),
              hideAppTitleBar: true,
              parentPath: '/online-shop/navigation',
            },
          },
        ],
      },
      {
        path: '/setting',
        name: 'setting',
        component: BlankLayout,
        meta: { title: $t('common.adminMenu.setting'), icon: IconMenuSetting },
        redirect: '/setting/setting-list',
        hideChildrenInMenu: true,
        children: [
          {
            path: '/setting/setting-list',
            name: 'setting-list',
            component: () => import('@/views/setting/setting-list/index.vue'),
            meta: {
              title: $t('setting.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_settinglist_show',
            },
          },
          {
            path: '/setting/shop-setting',
            name: 'shop-setting',
            component: () => import('@/views/setting/shop-setting/index.vue'),
            hidden: true,
            meta: {
              title: $t('setting.store.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_shopsetting_show',
            },
          },
          {
            path: '/setting/account-setting',
            name: 'account-setting',
            component: () => import('@/views/setting/account-setting/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.accountSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_accountsetting_show',
            },
          },
          {
            path: '/setting/payment-setting',
            name: 'payment-setting',
            component: () => import('@/views/setting/payment-setting/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.paymentSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentsetting_show',
            },
          },
          {
            path: '/setting/payment-ng-kyc-edit',
            name: 'payment-ng-kyc-edit',
            component: () => import('@/views/setting/payment-ng-kyc-edit/index.vue'),
            meta: {
              title: $t('common.adminMenu.paymentSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentngkycedit_show',
            },
          },
          {
            path: '/setting/payment-eg-kyc-edit',
            name: 'payment-eg-kyc-edit',
            component: () => import('@/views/setting/payment-eg-kyc-edit/index.vue'),
            meta: {
              title: $t('common.adminMenu.paymentSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentegkycedit_show',
            },
          },
          {
            path: '/setting/payment-ng-kyc-detail',
            name: 'payment-ng-kyc-detail',
            component: () => import('@/views/setting/payment-ng-kyc-detail/index.vue'),
            meta: {
              title: $t('common.adminMenu.paymentSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentngkycdetail_show',
            },
          },
          {
            path: '/setting/payment-service-terms',
            name: 'payment-service-terms',
            hidden: true,
            component: () => import('@/views/setting/payment-service-terms/index.vue'),
            meta: {
              title: $t('account.register.serviceTerms'),
              notNeedLogin: true,
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentserviceterms_show',
            },
          },
          {
            path: '/setting/payment-privacy-policy',
            name: 'payment-privacy-policy',
            hidden: true,
            component: () => import('@/views/setting/payment-privacy-policy/index.vue'),
            meta: {
              title: $t('account.register.privacy'),
              notNeedLogin: true,
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_paymentprivacypolicy_show',
            },
          },
          {
            path: '/setting/logistics-setting',
            name: 'logistics-setting',
            component: () => import('@/views/setting/logistics-setting/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.shippingFee'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_logisticssetting_show',
            },
          },
          {
            path: '/setting/tax-setting',
            name: 'tax-setting',
            component: () => import('@/views/setting/tax-setting/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.taxSetting'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_taxsetting_show',
            },
          },
          {
            path: '/setting/staff-list',
            name: 'staff-list',
            component: () => import('@/views/setting/staff-list/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.staff'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_stafflist_show',
            },
          },
          {
            path: '/setting/domain-list',
            name: 'domain-list',
            component: () => import('@/views/setting/domain-list/index.vue'),
            hidden: true,
            meta: {
              title: $t('common.adminMenu.domain'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_domainlist_show',
            },
          },
          {
            path: '/setting/domain-bind',
            name: 'domain-bind',
            component: () => import('@/views/setting/domain-bind/index.vue'),
            hidden: true,
            meta: {
              title: $t('setting.domain.bind'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_domainbind_show',
            },
          },
          {
            path: '/setting/notification-list',
            name: 'notification-list',
            component: () => import('@/views/setting/notification-list/index.vue'),
            hidden: true,
            meta: {
              title: $t('setting.notifications.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_notificationlist_show',
            },
          },
          {
            path: '/charge/index',
            redirect: '/setting/charge-setting',
          },
          {
            path: '/sms/index',
            redirect: '/setting/sms-setting',
          },
          {
            path: '/setting/charge-setting',
            name: 'charge-setting',
            component: () => import('@/views/setting/charge-setting/index.vue'),
            meta: {
              title: $t('setting.chargeManage.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_chargesetting_show',
            },
          },
          {
            path: '/setting/charge-edit',
            name: 'charge-list-edit',
            hidden: true,
            component: () => import('@/views/setting/charge-setting/components/charge-edit/index'),
            meta: {
              title: $t('charge.chargeList.editTitle'),
              hideAppTitleBar: true,
              showParentPath: true,
            },
          },
          {
            path: '/setting/sms-setting',
            name: 'sms-setting',
            component: () => import('@/views/setting/sms-setting/index.vue'),
            meta: {
              title: $t('setting.smsManage.title'),
              hideAppTitleBar: true,
              showParentPath: true,
              pv: 'spmerchant_smssetting_show',
            },
          },
        ],
      },
    ],
  },
]

// 获取门户外壳的页面路由
export const getPortalLayoutRoutes = () => [
  {
    path: '',
    name: 'portal',
    component: PortalLayout,
    redirect: '/portal/portal-home',
    children: [
      {
        path: '/portal/portal-home',
        name: 'portal-home',
        component: () => import('@/views/portal/portal-home/index.vue'),
        meta: {
          title: $t('portal.commonHeader.home'),
          notNeedLogin: true,
          isPromotionPage: true,
          pv: 'spportal_home_show',
        },
      },
      {
        path: '/portal/service-price',
        name: 'service-price',
        component: () => import('@/views/portal/service-price/index.vue'),
        meta: {
          title: $t('portal.commonHeader.price'),
          notNeedLogin: true,
          pv: 'spportal_serviceprice_show',
        },
      },
      {
        path: '/portal/blog-list',
        name: 'blog-list',
        component: () => import('@/views/portal/blog-list/index.vue'),
        meta: {
          title: $t('portal.commonHeader.blog'),
          menuHighlightPath: ['/portal/blog-list', '/portal/blog-detail'],
          notNeedLogin: true,
          pv: 'spportal_bloglist_show',
        },
      },
      {
        path: '/portal/blog-detail',
        name: 'blog-detail',
        hidden: true,
        component: () => import('@/views/portal/blog-detail/index.vue'),
        meta: {
          title: $t('portal.commonHeader.blog'),
          notNeedLogin: true,
          pv: 'spportal_blogdetail_show',
        },
      },
      {
        path: '/portal/app-download',
        name: 'app-download',
        component: () => import('@/views/portal/app-download/index.vue'),
        meta: {
          title: $t('portal.commonHeader.down'),
          notNeedLogin: true,
          pv: 'spportal_appdownload_show',
        },
      },
      {
        path: '/portal/help-center',
        name: 'help-enter',
        component: () => import('@/views/portal/help-center/index.vue'),
        meta: {
          title: $t('portal.commonHeader.helpCenter'),
          menuHighlightPath: ['/portal/help-center', '/portal/help-center-search-result'],
          notNeedLogin: true,
        },
      },
      {
        path: '/portal/help-center-search-result',
        name: 'help-center-search-result',
        hidden: true,
        component: () => import('@/views/portal/help-center-search-result/index.vue'),
        meta: { title: $t('portal.commonHeader.helpCenter'), notNeedLogin: true },
      },
    ],
  },
]

// 获取账号外壳的页面路由
export const getAccountLayoutRoutes = () => [
  {
    path: '/account',
    name: 'account',
    component: AccountLayout,
    redirect: '/account/login',
    children: [
      {
        path: '/account/login',
        name: 'login',
        component: () => import('@/views/account/login/index.vue'),
        meta: { title: $t('account.login.login'), pv: 'spportal_login_show', notNeedLogin: true },
      },
      {
        path: '/account/register',
        name: 'register',
        component: () => import('@/views/account/register/index.vue'),
        meta: {
          title: $t('account.login.register'),
          notNeedLogin: true,
          pv: 'spportal_register_show',
          isPromotionPage: true,
        },
      },
      {
        path: '/account/password-retrieve',
        component: () => import('@/views/account/password-retrieve/index.vue'),
        meta: { title: $t('account.login.forgetPassword'), notNeedLogin: true },
      },
    ],
  },
]

// 获取其他布局外壳的页面路由
export const getOtherLayoutRoutes = () => [
  {
    path: '/account/shop-list',
    name: 'shop-list',
    component: () => import('@/views/account/shop-list/index.vue'),
    meta: { title: '店铺选择', hideAppTitleBar: true, pv: 'spmerchant_shoplist_show' },
  },
  {
    path: '/account/shop-add',
    name: 'shop-add',
    component: () => import('@/views/account/shop-add/index.vue'),
    meta: { title: '店铺添加', hideAppTitleBar: true, pv: 'spmerchant_shopadd_show' },
  },
  {
    path: '/online-shop/shop-design',
    name: 'shop-design',
    component: () => import('@/views/online-shop/shop-design/index.vue'),
    hidden: true,
    meta: { title: '店铺设计', hideAppTitleBar: true, pv: 'spmerchant_shopdesign_show' },
  },
  {
    path: '/online-shop/theme-preview',
    name: 'theme-preview',
    component: () => import('@/views/online-shop/theme-preview/index.vue'),
    hidden: true,
    meta: {
      title: $t('home.shopMallPreview'),
      hideAppTitleBar: true,
      pv: 'spmerchant_themepreview_show',
    },
  },
  {
    path: '/portal/service-terms',
    name: 'service-terms',
    hidden: true,
    component: () => import('@/views/portal/service-terms/index.vue'),
    meta: { title: $t('account.register.serviceTerms'), notNeedLogin: true },
  },
  {
    path: '/portal/privacy-policy',
    name: 'privacy-policy',
    hidden: true,
    component: () => import('@/views/portal/privacy-policy/index.vue'),

    meta: { title: $t('account.register.privacy'), notNeedLogin: true },
  },
  {
    path: '*',
    redirect: '/404',
  },
]

// 获取活动布局外壳页面路由
export const getActivityLayoutRoutes = () => [
  {
    path: '/activity/referral',
    name: 'referral',
    component: () => import('@/views/activity/referral/index.vue'),
    hidden: true,
    meta: { title: 'referral活动', notNeedLogin: true, pv: 'spportal_referraldetail_show' },
  },
  {
    path: '/activity/landing-01',
    name: 'landing',
    component: () => import('@/views/activity/landing-01/index.vue'),
    hidden: true,
    meta: {
      title: 'Landing Page',
      pv: 'spportal_landing01_show',
      isPromotionPage: true,
      notNeedLogin: true,
    },
  },
  {
    path: '/activity/ngmktlanding',
    name: 'ngmktlanding',
    component: () => import('@/views/activity/ngmktlanding/index.vue'),
    hidden: true,
    meta: {
      title: 'ngmktlanding Page',
      isPromotionPage: true,
      pv: 'spportal_ngmktlanding_show',
      notNeedLogin: true,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
]

// 获取全部路由
export const getAllRoutes = () => [
  ...getPortalLayoutRoutes(),
  ...getAdminLayoutRoutes(),
  ...getAccountLayoutRoutes(),
  ...getOtherLayoutRoutes(),
  ...getActivityLayoutRoutes(),
]
